export const steps = [
  'basic-check',
  'vehicle-info',
  'seller-info',
  'owner-info',
  'offer-agreement',
  'assessment-complete',
  'book-inspection',
  'inspection-booking',
  'inspection-issues',
  'documents',
  'contract',
  'arrange-pickup',
  'pickup-booking',
  'sold',
];

export const DEFAULT_COLOR = {
  PRIMARY: '#FFE04B',
  SECONDARY: '#28477C',
};

export enum APPRAISAL_STATUS {
  Pending = 'Pending',
  Completed = 'Completed',
  Discarded = 'Discarded',
}

export enum APPRAISAL_CUSTOMER_ACTION {
  Interested = 'Interested',
  NotInterested = 'Not Interested',
}

export enum ErrorMessage {
  NotFound = 'We can‘t find the page you’re looking for',
  Error = 'Something has gone wrong...',
}

export enum ImageSource {
  LISTING = 'listing',
  STOCK = 'stock',
  INSPECTION = 'inspection',
  APPRAISAL = 'appraisal',
  INVENTORY = 'inventory',
}
