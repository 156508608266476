import { Icon } from '../../models/Icon';

const Odometer = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 18 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9 0C4.02938 0 0 4.02938 0 9C0 10.65 0.445313 12.1956 1.22063 13.525C1.39594 13.8256 1.73 14 2.07812 14H15.9219C16.27 14 16.6041 13.8256 16.7794 13.525C17.5547 12.1956 18 10.65 18 9C18 4.02938 13.9706 0 9 0ZM10.7225 12H7.2775C7.10563 11.7047 7 11.3663 7 11C7 10.6228 7.11063 10.2741 7.29219 9.97313L3.9 5.45031C3.65156 5.11875 3.71875 4.64844 4.05 4.4C4.3825 4.1525 4.85188 4.21969 5.10031 4.55L8.49281 9.07312C8.65562 9.03031 8.82375 9 9.00031 9C10.105 9 11.0003 9.89531 11.0003 11C11 11.3663 10.8944 11.7047 10.7225 12Z"
      fill="currentColor"
    />
  </svg>
);

export default Odometer;
