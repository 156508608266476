import { Icon } from '../../models/Icon';

const Info = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8 16.5C12.4183 16.5 16 12.9183 16 8.5C16 4.08172 12.4183 0.5 8 0.5C3.58172 0.5 0 4.08172 0 8.5C0 12.9183 3.58172 16.5 8 16.5ZM8.9307 7.08789L7.92875 11.793C7.85844 12.1328 7.95805 12.3262 8.23344 12.3262C8.4268 12.3262 8.71977 12.2559 8.91898 12.0801L8.83109 12.4961C8.54398 12.8418 7.91117 13.0938 7.36625 13.0938C6.66313 13.0938 6.3643 12.6719 6.55766 11.7754L7.29594 8.30664C7.36039 8.01367 7.3018 7.9082 7.00883 7.83789L6.55766 7.75586L6.63969 7.375L8.9307 7.08789ZM8 6C7.44772 6 7 5.55228 7 5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5C9 5.55228 8.55229 6 8 6Z"
      fill="currentColor"
    />
  </svg>
);

export default Info;
