import { Icon } from '../../models/Icon';

const Calendar = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 15 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.4583 9.00008H7.5V12.9584H11.4583V9.00008ZM10.6667 0.291748V1.87508H4.33333V0.291748H2.75V1.87508H1.95833C1.07958 1.87508 0.382917 2.58758 0.382917 3.45841L0.375 14.5417C0.375 15.4126 1.07958 16.1251 1.95833 16.1251H13.0417C13.9125 16.1251 14.625 15.4126 14.625 14.5417V3.45841C14.625 2.58758 13.9125 1.87508 13.0417 1.87508H12.25V0.291748H10.6667ZM13.0417 14.5417H1.95833V5.83341H13.0417V14.5417Z"
      fill="currentColor"
    />
  </svg>
);

export default Calendar;
