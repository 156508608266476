import { Icon } from '../../models/Icon';

const ArrowUp = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15 8L9.50515 1.72017C8.70833 0.809518 7.29167 0.80952 6.49485 1.72017L1 8"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowUp;
