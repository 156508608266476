import { Icon } from '../../models/Icon';

const VehicleCrash = ({ className }: Icon) => (
  <svg
    width="138"
    height="113"
    viewBox="0 0 138 113"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M39.5614 0C41.0603 0 42.3776 0.705957 42.9454 2.29778L49.6226 19.1405L66.906 13.8755C68.3369 13.4428 69.8812 13.9233 70.8124 15.0915C70.926 15.2236 71.0168 15.3603 71.1077 15.5037C67.224 17.3802 63.7946 20.2519 61.2282 23.9798L51.9164 37.4613C45.7162 39.4881 40.6061 44.5208 38.7892 51.3299L34.2696 68.1818L26.2524 75.583C25.1623 76.6077 23.5497 76.8355 22.2188 76.1978C20.8766 75.5374 20.0658 74.1483 20.1703 72.6453L21.4398 54.5637L3.57723 51.7854C2.10553 51.5576 0.922485 50.4645 0.591351 49.0071C0.25999 47.5496 0.84731 46.0238 2.07373 45.1813L16.9634 34.9107L7.99462 19.1702C7.2565 17.8744 7.38141 16.2575 8.31032 15.0915C9.23695 13.9233 10.7813 13.4428 12.2053 13.8755L29.5002 19.1405L36.1773 2.29778C36.7451 0.705957 38.0624 0 39.5614 0ZM86.8467 20.8758L117.644 28.9442C125.07 31.1304 130.43 37.5979 131.043 45.2723L132.61 64.3787C136.517 67.4531 138.425 72.6453 137.062 77.7464L129.067 107.67C128.023 111.541 123.844 113.659 120.165 112.816L116.667 111.883C112.783 110.835 110.489 106.827 111.511 102.956L113.397 95.8963L57.2309 80.8207L55.3686 87.8575C54.1194 91.7516 50.3266 94.0517 46.4657 93.0042L42.9454 92.0705C39.0617 91.0229 36.7678 87.0377 37.8126 83.1435L45.807 53.2201C47.1697 48.1189 51.4168 44.5892 56.3225 43.8832L67.2013 28.1244C71.5619 21.7708 79.4428 18.8832 86.8467 20.8758ZM83.0993 34.9563C81.6004 34.5464 80.0333 35.1384 79.1702 36.391L71.8344 47.0259L117.598 59.3232L116.553 46.2516C116.44 44.908 115.372 43.6327 113.873 43.2228L83.0993 34.9563ZM60.5241 68.5006C63.4312 69.2749 66.4291 67.5442 67.2013 64.6292C67.9962 61.7143 66.2701 58.7311 63.363 57.934C60.2516 57.1598 57.458 58.8905 56.6858 61.8054C55.8909 64.7203 57.617 67.7263 60.5241 68.5006ZM119.506 73.0324C116.599 72.2581 113.624 73.9889 112.829 76.9038C112.057 79.8187 113.783 82.8019 116.69 83.599C119.597 84.3733 122.595 82.6425 123.367 79.7276C124.139 76.8127 122.413 73.8067 119.506 73.0324Z"
      fill="currentColor"
    />
  </svg>
);

export default VehicleCrash;
