import { ErrorMessage } from '../../utils/constants';
import Contact, { ContactInfo } from '../Contact/Contact';
import { VehicleCrash } from '../Icons';

interface ErrorBlockProps {
  statusCode?: number;
  contactInfo?: ContactInfo;
}

const ErrorBlock = ({ statusCode = 500, contactInfo }: ErrorBlockProps) => (
  <div className="text-center" style={{ padding: '10rem 2rem' }}>
    <VehicleCrash className="custom-text-secondary" />
    <br />
    <h2 className="custom-text-secondary fw-bold">
      Oops! Looks like you took a wrong turn
    </h2>
    <br />
    <p className="text-center">
      {statusCode === 404 ? ErrorMessage.NotFound : ErrorMessage.Error}
    </p>
    <strong className="mt-4 mb-5">{`ERROR CODE: ${statusCode}`}</strong>
    <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
      <Contact contactInfo={contactInfo} />
    </div>
  </div>
);

export default ErrorBlock;
