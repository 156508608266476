import { Icon } from '../../models/Icon';

const Fuel = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 16 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M8 0.5C2.67 5.05 0 8.98 0 12.3C0 17.28 3.8 20.5 8 20.5C12.2 20.5 16 17.28 16 12.3C16 8.98 13.33 5.05 8 0.5ZM3.83 12.5C4.2 12.5 4.5 12.76 4.57 13.12C4.98 15.34 6.85 16.1 8.21 15.99C8.64 15.97 9 16.31 9 16.74C9 17.14 8.68 17.47 8.28 17.49C6.15 17.62 3.66 16.4 3.09 13.37C3.01 12.92 3.37 12.5 3.83 12.5Z"
      fill="currentColor"
    />
  </svg>
);

export default Fuel;
