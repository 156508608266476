import { Icon } from '../../models/Icon';

const Transmission = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 17 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 0C13.3954 0 12.5 0.783475 12.5 1.75C12.5 2.39733 12.9028 2.96117 13.5 3.26393V6.125H10H9.5V3.26393C9.6884 3.16838 9.857 3.04692 10 2.90482C10.3102 2.59648 10.5 2.1931 10.5 1.75C10.5 0.783475 9.6046 0 8.5 0C7.3954 0 6.5 0.783475 6.5 1.75C6.5 2.39733 6.9028 2.96117 7.5 3.26393V6.125H3.5V3.26393C4.0972 2.96117 4.5 2.39733 4.5 1.75C4.5 0.783475 3.6046 0 2.5 0C1.3954 0 0.5 0.783475 0.5 1.75C0.5 2.39733 0.9028 2.96117 1.5 3.26393V10.7359C0.9028 11.0386 0.5 11.6025 0.5 12.2498C0.5 13.2163 1.3954 13.9998 2.5 13.9998C3.6046 13.9998 4.5 13.2163 4.5 12.2498C4.5 11.6025 4.0972 11.0385 3.5 10.7359V7.875H7.5V10.7361C6.9028 11.0388 6.5 11.6027 6.5 12.25C6.5 13.2165 7.3954 14 8.5 14C9.6046 14 10.5 13.2165 10.5 12.25C10.5 11.8069 10.3102 11.4035 10 11.0952C9.857 10.9531 9.6884 10.8316 9.5 10.7361V7.875H10H14.5C15.0522 7.875 15.5 7.48318 15.5 7V3.26393C16.0972 2.96117 16.5 2.39733 16.5 1.75C16.5 0.783475 15.6046 0 14.5 0Z"
      fill="currentColor"
    />
  </svg>
);

export default Transmission;
