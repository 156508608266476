import { Icon } from '../../models/Icon';

const Vehicle = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 22 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21.4764 7.18941L20.9209 6.21909C20.8959 6.17632 20.8551 6.14516 20.8072 6.13243C20.7593 6.11969 20.7084 6.12641 20.6654 6.15112L19.4772 6.84487L17.642 1.77769C17.5451 1.47533 17.3546 1.21159 17.0981 1.02454C16.8415 0.837494 16.5322 0.736822 16.2147 0.737061H6.0592C5.24591 0.737061 4.52404 1.26206 4.27326 2.03784L2.52482 6.84722L1.3342 6.15347C1.29127 6.12876 1.24031 6.12204 1.19245 6.13477C1.14458 6.1475 1.1037 6.17866 1.07873 6.22144L0.523258 7.18941C0.471696 7.27847 0.502165 7.39097 0.591227 7.44253L2.00685 8.26753L1.66701 9.20503C1.63888 9.28003 1.62482 9.35972 1.62482 9.43941V17.6003C1.62482 17.9683 1.90138 18.266 2.24123 18.266H3.8256C4.11388 18.266 4.36466 18.048 4.4256 17.7433L4.60607 16.8597H17.3936L17.574 17.7433C17.6373 18.048 17.8858 18.266 18.174 18.266H19.7584C20.0983 18.266 20.3748 17.9683 20.3748 17.6003V9.43941C20.3748 9.35972 20.3608 9.28003 20.3326 9.20503L19.9928 8.26753L21.4061 7.44253C21.4486 7.41796 21.4798 7.37768 21.493 7.33034C21.5061 7.283 21.5002 7.2324 21.4764 7.18941ZM5.18732 12.055C4.66935 12.055 4.24982 11.6355 4.24982 11.1175C4.24982 10.5996 4.66935 10.18 5.18732 10.18C5.70529 10.18 6.12482 10.5996 6.12482 11.1175C6.12482 11.6355 5.70529 12.055 5.18732 12.055ZM14.2811 13.8128C14.2811 13.916 14.1967 14.0003 14.0936 14.0003H7.90607C7.80295 14.0003 7.71857 13.916 7.71857 13.8128V11.8441C7.71857 11.741 7.80295 11.6566 7.90607 11.6566H8.84357C8.9467 11.6566 9.03107 11.741 9.03107 11.8441V12.6878H12.9686V11.8441C12.9686 11.741 13.0529 11.6566 13.1561 11.6566H14.0936C14.1967 11.6566 14.2811 11.741 14.2811 11.8441V13.8128ZM16.8123 12.055C16.2944 12.055 15.8748 11.6355 15.8748 11.1175C15.8748 10.5996 16.2944 10.18 16.8123 10.18C17.3303 10.18 17.7498 10.5996 17.7498 11.1175C17.7498 11.6355 17.3303 12.055 16.8123 12.055ZM4.15607 7.29722L5.85998 2.61206L5.8717 2.58159L5.88107 2.55112C5.90685 2.47378 5.97716 2.42222 6.0592 2.42222H16.0811L17.8483 7.29722H4.15607Z"
      fill="currentColor"
    />
  </svg>
);

export default Vehicle;
