import { Icon } from '../../models/Icon';

const ArrowDown = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M1 1L6.49485 7.27983C7.29167 8.19048 8.70833 8.19048 9.50515 7.27982L15 1"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default ArrowDown;
