import { Icon } from '../../models/Icon';

const Telephone = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 30 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M6.85101 2.49092C6.38144 1.88718 5.48897 1.83161 4.94813 2.37245L3.00953 4.31106C2.10301 5.21757 1.76976 6.50185 2.16582 7.62838C3.75633 12.1523 6.36029 16.4001 9.9801 20.0199C13.5999 23.6397 17.8477 26.2437 22.3716 27.8342C23.4981 28.2302 24.7824 27.897 25.6889 26.9905L27.6276 25.0519C28.1684 24.511 28.1128 23.6186 27.5091 23.149L23.185 19.7858C22.8765 19.5459 22.475 19.4611 22.0959 19.5559L17.9914 20.582C16.8765 20.8607 15.6972 20.5341 14.8846 19.7215L10.2785 15.1154C9.46592 14.3028 9.13926 13.1235 9.41798 12.0086L10.4441 7.90411C10.5389 7.52504 10.4541 7.12346 10.2142 6.81503L6.85101 2.49092ZM3.53395 0.958267C4.92541 -0.433197 7.22155 -0.290245 8.42968 1.26306L11.7929 5.58717C12.4101 6.3807 12.6282 7.41391 12.3843 8.38917L11.3582 12.4937C11.2499 12.927 11.3769 13.3854 11.6927 13.7012L16.2988 18.3073C16.6146 18.6231 17.073 18.7501 17.5063 18.6418L21.6108 17.6157C22.5861 17.3718 23.6193 17.5899 24.4128 18.2071L28.7369 21.5703C30.2902 22.7784 30.4332 25.0746 29.0417 26.4661L27.1031 28.4047C25.7162 29.7916 23.6416 30.4007 21.7083 29.7209C16.9079 28.0332 12.402 25.2701 8.56592 21.4341C4.72987 17.598 1.96678 13.0921 0.279071 8.29172C-0.400654 6.35836 0.208369 4.28385 1.59534 2.89687L3.53395 0.958267Z"
      fill="currentColor"
    />
  </svg>
);

export default Telephone;
