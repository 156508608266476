const RangeBar = ({ width = '100%' }) => (
  <svg
    width={width}
    viewBox="0 0 306 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.0415"
      width="304.103"
      height="16"
      rx="4"
      fill="url(#paint0_linear_64_12367)"
    />
    <path
      d="M12.3488 25.8863C11.9003 26.5891 11.6761 26.9405 11.3905 27.0616C11.1409 27.1675 10.8591 27.1675 10.6095 27.0616C10.3239 26.9405 10.0997 26.5891 9.65122 25.8863L3.47696 16.2107C2.96523 15.4088 2.70937 15.0078 2.73382 14.6764C2.75513 14.3876 2.9006 14.1222 3.13261 13.9489C3.39882 13.75 3.87446 13.75 4.82574 13.75L17.1743 13.75C18.1255 13.75 18.6012 13.75 18.8674 13.9489C19.0994 14.1222 19.2449 14.3876 19.2662 14.6764C19.2906 15.0078 19.0348 15.4088 18.523 16.2107L12.3488 25.8863Z"
      fill="#FF5E01"
    />
    <path
      d="M297.32 24.0752C296.877 24.7211 296.655 25.044 296.378 25.1572C296.136 25.2563 295.864 25.2563 295.622 25.1572C295.345 25.044 295.123 24.7211 294.68 24.0752L289.49 16.5047C288.933 15.6928 288.655 15.2869 288.672 14.9495C288.687 14.6556 288.83 14.3833 289.064 14.2048C289.333 14 289.825 14 290.81 14L301.19 14C302.175 14 302.667 14 302.936 14.2048C303.17 14.3833 303.313 14.6556 303.328 14.9495C303.345 15.2869 303.067 15.6928 302.51 16.5047L297.32 24.0752Z"
      fill="#04FE1A"
    />
    <defs>
      <linearGradient
        id="paint0_linear_64_12367"
        x1="1.50792"
        y1="1.09139e-10"
        x2="305.61"
        y2="1.52616e-08"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5C00" />
        <stop offset="0.261733" stopColor="#ECF02D" />
        <stop offset="0.485008" stopColor="#D9E679" />
        <stop offset="0.716258" stopColor="#4DCE12" />
        <stop offset="1" stopColor="#00FF19" />
      </linearGradient>
    </defs>
  </svg>
);

export default RangeBar;
