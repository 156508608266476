import { Icon } from '../../models/Icon';

const Info = ({ size = 1, className }: Icon) => (
  <svg
    height={`${size}rem`}
    viewBox="0 0 35 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M28.8125 0.3125H1.1875C0.599805 0.3125 0.125 0.787305 0.125 1.375V22.625C0.125 23.2127 0.599805 23.6875 1.1875 23.6875H28.8125C29.4002 23.6875 29.875 23.2127 29.875 22.625V1.375C29.875 0.787305 29.4002 0.3125 28.8125 0.3125ZM27.4844 3.99141V21.2969H2.51562V3.99141L1.59922 3.27754L2.9041 1.60078L4.3252 2.70645H25.6781L27.0992 1.60078L28.4041 3.27754L27.4844 3.99141ZM25.6781 2.70312L15 11.0039L4.32187 2.70312L2.90078 1.59746L1.5959 3.27422L2.5123 3.98809L13.8545 12.8068C14.1807 13.0602 14.582 13.1978 14.995 13.1978C15.4081 13.1978 15.8094 13.0602 16.1355 12.8068L27.4844 3.99141L28.4008 3.27754L27.0959 1.60078L25.6781 2.70312Z"
      fill="currentColor"
    />
  </svg>
);

export default Info;
