import cx from 'classnames';

import Mail from '../Icons/Mail';
import Telephone from '../Icons/Telephone';

import s from './Contact.module.scss';

export type ContactInfo = {
  phone?: string;
  email?: string;
};

type ContactProps = {
  textClassName?: string;
  contactInfo?: ContactInfo;
};

const Contact = ({ textClassName, contactInfo }: ContactProps) => (
  <div className="d-md-flex justify-content-md-center">
    <a
      className="d-flex align-items-baseline px-md-4"
      href={`mailto:${contactInfo?.email}`}
    >
      {contactInfo?.email && (
        <>
          <Mail size={1} className="text-black" />
          <span
            className={cx(
              'custom-text-secondary fw-bold py-2 ps-md-4 ps-2',
              textClassName,
              s.contact,
            )}
          >
            {contactInfo?.email}
          </span>
        </>
      )}
    </a>
    <a
      className="d-flex align-items-baseline px-md-4"
      href={`tel:${contactInfo?.phone}`}
    >
      {contactInfo?.phone && (
        <>
          <Telephone size={1.5} className="text-black" />
          <span
            className={cx(
              'custom-text-secondary fw-bold py-2 ps-md-4 ps-2',
              textClassName,
              s.contact,
            )}
          >
            {contactInfo?.phone}
          </span>
        </>
      )}
    </a>
  </div>
);

export default Contact;
